import React from 'react';
import Layout from '../components/Layout/Layout';
import Home from '../components/Home/Home';
import Seo from '../components/Seo/Seo';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

export default () => {
  return (
    <>
      <Seo />
      <Layout>
        <Home />
      </Layout>
    </>
  );
};
