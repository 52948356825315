import React, { useContext, useState, useEffect } from 'react';

import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Tilt from 'react-tilt';
import PortfolioContext from '../../context/context';
import Subtitle from '../Subtitle/Subtitle';
import ProjectImg from '../Image/ProjectImg';

const LymphaticDrainage = () => {

  const { lymphaticDrainage } = useContext(PortfolioContext);
  const { title, massages } = lymphaticDrainage;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id='lymphaticDrainage'>
      <Container>
        <div className='project-wrapper'>
          <Subtitle subtitle={title || ''} />
          {massages !== undefined && massages.map((massage) => {
            const { title, description, details, image, duration, price, other, otherDuration, otherTarif } = massage;

            return (
              <Row key={massage.id}>
                <Col lg={4} sm={12}>
                  <Fade
                    left={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={500}
                    distance='30px'
                  >
                    <div className='project-wrapper__text'>
                      <h3 className='project-wrapper__text-title'>{title || 'Project Title'}</h3>
                      <div>
                        <p>{description}</p>
                        <ul>
                          {details.map(d => <li key={d}><h4>{d || ''}</h4></li>)}
                        </ul>
                        <p className='mb-4 duration'>Durée : {duration || ''}</p>
                        <p className='mb-4'>Tarif : {price || ''}</p>
                        <h3 className='project-wrapper__text-subtitle'>{other || ''}</h3>
                        <p className='mb-4 duration'>Durée : {otherDuration || ''}</p>
                        <p className='mb-4'>Tarif : {otherTarif || ''}</p>
                      </div>
                    </div>
                  </Fade>
                </Col>
                <Col lg={8} sm={12}>
                  <Fade
                    right={isDesktop}
                    bottom={isMobile}
                    duration={1000}
                    delay={1000}
                    distance='30px'
                  >
                    <div className='project-wrapper__image'>

                      <Tilt
                        options={{
                          reverse: false,
                          max: 8,
                          perspective: 1000,
                          scale: 1,
                          speed: 300,
                          transition: true,
                          axis: null,
                          reset: true,
                          easing: 'cubic-bezier(.03,.98,.52,.99)',
                        }}
                      >
                        <div data-tilt className='thumbnail rounded'>
                          <ProjectImg alt={title} filename={image} />
                        </div>
                      </Tilt>
                    </div>
                  </Fade>
                </Col>
              </Row>
            );
          })}
        </div>
      </Container>
    </section>
  );
};

export default LymphaticDrainage;
