import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';

const ContactMe = () => {
  const { contact } = useContext(PortfolioContext);
  const { title, locations, phone } = contact;
  const locationsItems = locations?.map((location) => (
    <li key={location}>
      <span className="text-wrapper">{location}</span>
    </li>
  ));

  return (
    <section id="contactMe">
      <Container fluid>
        <h2 className="display-3 pa-3 text-color-white">Me contacter</h2>
        <section>
          <Container>
            <Fade bottom duration={1000} delay={800} distance="30px">
              <div className="contact-wrapper">
                <p className="contact-wrapper__text">{title}</p>
                <ul>{locationsItems}</ul>
                <div className="py-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cta-btn cta-btn--resume"
                    href={`tel:${phone}`}
                  >
                    {phone}
                  </a>
                </div>
              </div>
            </Fade>
          </Container>
        </section>
      </Container>
    </section>
  );
};

export default ContactMe;
