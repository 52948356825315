import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, paragraphOne, paragraphTwo, paragraphThree, paragraphFour, phone, btn } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id='about'>
      <Container>
        <h2 className="display-3 pa-3 text-color-white">Qui suis-je ?</h2>
        <section>
          <Container>
            <Row className='about-wrapper'>
              <Col md={6} sm={12}>
                <Fade bottom duration={1000} delay={600} distance='30px'>
                  <div className='about-wrapper__image'>
                    <AboutImg alt='profile picture' filename={img} />
                  </div>
                </Fade>
              </Col>
              <Col md={6} sm={12}>
                <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance='30px'>
                  <div className='about-wrapper__info'>
                    <p className='about-wrapper__info-text'>{paragraphOne}</p>
                    <p className='about-wrapper__info-text'>{paragraphTwo}</p>
                    <p className='about-wrapper__info-text'>{paragraphThree}</p>
                    <p className='about-wrapper__info-text'>{paragraphFour}</p>
                    <a
                      target='_blank'
                      rel='noopener noreferrer'
                      className='cta-btn cta-btn--resume'
                      href={`tel:${phone}`}
                    >
                      {btn}
                    </a>
                  </div>
                </Fade>
              </Col>
            </Row>
            <Row className='about-wrapper'>
              <Col md={12} sm={12}>
                <Fade bottom duration={1000} delay={600} distance='30px'>
                  <iframe
                    allow="autoplay"
                    className="video-iframe"
                    src="https://www.youtube-nocookie.com/embed/qtUPoStPbAk?autoplay=1&mute=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen>
                  </iframe>
                </Fade>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </section>
  );
};

export default About;
