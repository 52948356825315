import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Subtitle from '../Subtitle/Subtitle';

const Warning = () => {

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id='warning'>
      <Container>
        <div className='project-wrapper'>
          <hr />
          <Subtitle subtitle={'Avertissement'} />
          <h3>Les massages ne sauraient remplacer le suivi médical habituel ou en cours, ni se substituer aux traitements médicaux en cours.</h3>
        </div>
      </Container>
    </section>
  );
};

export default Warning;
