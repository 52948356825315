import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, subtitle, paragraphOne, paragraphTwo, paragraphThree, img } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id='hero' className='hero'>
      <Container fluid>
        <div className='row'>
          <div className='col-md-12'>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance='30px'>
            </Fade>
            <div className='col-md-10 col-xs-12 hero-background'>
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={500}
                distance='30px'
              >
                <h1 className='hero-title'>
                  <div className='text-color-main break'>{title}</div>
                  <div className='text-color-main break'>{subtitle}</div>
                </h1>
              </Fade>
              <Fade
                left={isDesktop}
                bottom={isMobile}
                duration={1000}
                delay={1000}
                distance='30px'>
                <h1 className='hero-details'>
                  <div className='text-color-main'>{paragraphOne}</div>
                  <div className='text-color-main'>{paragraphTwo}</div>
                  <div className='text-color-main'>{paragraphThree}</div>
                </h1>
              </Fade>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default Header;
