import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

import { PortfolioProvider } from '../../context/context';

import {
  headerData,
  heroData,
  aboutData,
  projectsData,
  contactData,
  wellnessMassagesData,
  japaneseFacialMassageData,
  lymphaticDrainageData,
  shiatsuData,
  footerData,
} from '../../mock/data';

export default function Layout({ children }) {
  const [header, setHeader] = useState({});
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [wellnessMassages, setWellnessMassages] = useState({});
  const [japaneseFacialMassage, setJapaneseFacialMassage] = useState({});
  const [lymphaticDrainage, setLymphaticDrainage] = useState({});
  const [shiatsu, setShiatsu] = useState({});
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHeader({ ...headerData });
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setWellnessMassages({ ...wellnessMassagesData });
    setJapaneseFacialMassage({ ...japaneseFacialMassageData });
    setLymphaticDrainage({ ...lymphaticDrainageData });
    setShiatsu({ ...shiatsuData});
    setFooter({ ...footerData });
  }, []);
  return (
    <PortfolioProvider value={{ header, hero, about, projects, contact, wellnessMassages, japaneseFacialMassage, lymphaticDrainage, shiatsu, footer }}>
      <Header />
      <main>{children}</main>
      <Footer />
    </PortfolioProvider>
  );
}
