import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import WellnessMassages from './WellnessMassages';
import JapaneseFacialMassage from './JapaneseFacialMassage';
import LymphaticDrainage from './LymphaticDrainage';
import Shiatsu from './Shiatsu';
import Warning from './Warning';
import Title from '../Title/Title';

const Services = ({ children }) => {
  return (
    <section id='services'>
      <Container fluid>
        <h2 className='display-3 pa-3 text-color-main'>Mes Services</h2>
        <WellnessMassages />
        <JapaneseFacialMassage />
        <LymphaticDrainage />
        <Shiatsu />
        <Warning />
      </Container>
    </section>
  );
};

export default Services;
