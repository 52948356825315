import React, { useContext } from 'react';
import PortfolioContext from '../../context/context';
import { Row, Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import LogoImg from '../Image/LogoImg';

const Header = () => {
  const { header } = useContext(PortfolioContext);
  const { img, facebookUrl, instagramUrl } = header;

  return (
    <header id='header' className='header'>
      <Navbar collapseOnSelect expand='md'>
        <Row xs={12}>
          <Navbar.Brand className='mx-auto order-0' bg='dark' variant='dark'>
            <LogoImg alt='profile picture' filename={img} />
            <h1 className='display-4 font-weight-bold text-color-main'>Lucia RODRIGUES</h1>
            <h1 className='display-5 font-weight-bold text-color-secondary'>Massage</h1>
          </Navbar.Brand>
        </Row>
        <Navbar.Toggle aria-controls='responsive-navbar-nav' />
        <Navbar.Collapse id='responsive-navbar-nav' className='w-100 order-1 order-md-0'>
          <Nav className='mr-auto'>
            <Link to='header' className='px-5' smooth duration={1000}>Accueil</Link>
            <Link to='services' className='px-5' smooth duration={1000}>Services</Link>
            <Link to='contactMe' className='px-5' smooth duration={1000}>Contact</Link>
          </Nav>
          <Nav className='ml-auto'>
            <a href={facebookUrl || ''} target='_blank' className='px-5'>
              <span className='fa fa-facebook' />
            </a>
            <a href={instagramUrl || ''} target='_blank' className='px-5'>
              <span className='fa fa-instagram' />
            </a>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
