import React from 'react';
import Hero from '../Hero/Hero';
import About from '../About/About';
import Services from '../Services/Services';
// import Projects from "../Projects/Projects";
import ContactMe from '../Contact/ContactMe';

import { PortfolioProvider } from '../../context/context';
import { heroData, aboutData, projectsData, contactData } from '../../mock/data';

export default function Home({ children }) {
  return (
    <>
      <Hero />
      <About />
      <Services />
      <ContactMe />
    </>
  );
}
